import React from "react";
import Header from "./Header";
import { getMethod,postMethod } from "../core/service/common.api";
import apiService from "../core/service/detail";
import { Link, useNavigate } from "react-router-dom";
import useState from "react-usestateref";
import { toast } from "react-toastify";
import { Bars } from "react-loader-spinner";


const Anti = () => {

  const navigate = useNavigate();

  const initialFormValue = {
    oldpassword: "",
    password: "",
    confirmPassword: "",
  };
  const [formValue, setFormValue] = useState(initialFormValue);

  const { oldpassword, password, confirmPassword } = formValue;
  const [passwordValidate, setpasswordValidate, passwordValidateref] =
    useState(false);
  const [
    confirmPasswordValidate,
    setconfirmPasswordValidate,
    confirmPasswordValidateref,
  ] = useState(false);
  const [oldpassvalidate, setoldpassvalidate, oldpassvalidateref] =
  useState(false);
const [passHide, setPasshide] = useState(false);
const [inputType, setinputType] = useState("password");
const [passHidconf, setPasshideconf] = useState(false);
const [inputTypeconf, setinputTypeconf] = useState("password");
const [passHidnew, setPasshidenew] = useState(false);
const [inputTypenew, setinputTypenew] = useState("password");
const [buttonLoader, setbuttonLoader] = useState(false);
const [validationnErr, setvalidationnErr] = useState("");

const validate = async (values) => {
  const errors = {};
  if (values.oldpassword == "") {
    errors.oldpassword = "Old password is required";
    setoldpassvalidate(true);
  } else {
    setoldpassvalidate(false);
  }

  if (values.password == "") {
    setpasswordValidate(true);
    errors.password = "Password is required";
  } else if (values.password.length < 15 || values.password.length > 30) {
    setpasswordValidate(true);
    errors.password =
      "New password is required and shouldnot below 15 above 30 letters";
  } else if (!values.password.match(/[a-z]/g)) {
    setpasswordValidate(true);
    errors.password = "Please enter at least lower character";
  } else if (!values.password.match(/[A-Z]/g)) {
    setpasswordValidate(true);
    errors.password = "Please enter at least upper character";
  } else if (!values.password.match(/[0-9]/g)) {
    setpasswordValidate(true);
    errors.password = "Please enter One digit character";
  } else if (values.password == values.oldpassword) {
    errors.password = "Old password and New password should not be same";
    setpasswordValidate(true);
  } else {
    setpasswordValidate(false);
  }

  if (values.confirmPassword == "") {
    errors.confirmPassword = "Confirm password is a required";
    setconfirmPasswordValidate(true);
  } else if (values.confirmPassword != values.password) {
    setconfirmPasswordValidate(true);
    errors.confirmPassword = "Password and confirm password does not match";
  } else {
    setconfirmPasswordValidate(false);
  }
  setvalidationnErr(errors);
  return errors;
};

const [buttonStatus, setButtonstatus] = useState(false);
const [siteLoader, setSiteLoader] = useState(false);

const handleChange = async (e) => {
  e.preventDefault();
  const { name, value } = e.target;
  let formData = { ...formValue, ...{ [name]: value } };
  setFormValue(formData);
  validate(formData);
  if (
    confirmPasswordValidateref.current == false &&
    passwordValidateref.current == false &&
    oldpassvalidateref.current == false
  ) {
    setButtonstatus(true);
  } else {
    setButtonstatus(false);
  }
};

const formSubmit = async () => {
  validate(formValue);
  console.log(formValue, "formValue");
  if (
    confirmPasswordValidateref.current == false &&
    passwordValidateref.current == false &&
    oldpassvalidateref.current == false
  ) {
    var obj = {
      oldPass: formValue.oldpassword,
      password: formValue.password,
      cpass: formValue.confirmPassword,
    };

    var data = {
      apiUrl: apiService.changePassword,
      payload: obj,
    };
    setbuttonLoader(true);
    var resp = await postMethod(data);
    // localStorage.setItem("useremail", resp.email);
    sessionStorage.setItem("useremail", resp.email);
    setbuttonLoader(false);
    if (resp.status == true) {
      showsuccessToast(resp.Message);
      // localStorage.clear();
      sessionStorage.clear();
      navigate("/login");
      window.location.reload(true);
    } else {
      showerrorToast(resp.Message);
    }
  }
};

const passwordHide = (data) => {
  if (data == "hide") {
    setPasshide(true);
    setinputType("text");
  } else {
    setPasshide(false);
    setinputType("password");
  }
};

const passwordHideconf = (data) => {
  if (data == "hide") {
    setPasshideconf(true);
    setinputTypeconf("text");
  } else {
    setPasshideconf(false);
    setinputTypeconf("password");
  }
};

const passwordHidenewP = (data) => {
  if (data == "hide") {
    setPasshidenew(true);
    setinputTypenew("text");
  } else {
    setPasshidenew(false);
    setinputTypenew("password");
  }
};

const showsuccessToast = (message) => {
  toast.dismiss();
  toast.success(message);
};

const showerrorToast = (message) => {
  toast.dismiss();
  toast.error(message);
};


  return (
    <>
      <section className="Non_fixed_nav">
        <Header />
      </section>
      {siteLoader == true ? (
          <div className="loadercss">
          <Bars
            height="80"
            width="80"
            color="#ffc630"
            ariaLabel="bars-loading"
            wrapperStyle={{}} 
            wrapperClass=""
            visible={true}
            />  
          </div>
          ) : (
            <main className="anti_phishing_main">
            <div>
              <div className="Verification ">
                <div className="container">
                  <div>
                  <Link to="/security">
                    <h6>
                      {" "}
                      <i class="fa-solid fa-arrow-left-long mr-3"></i> Security
                    </h6>
                    </Link>
                    <div className="row justify-content-center cards">
                      <div className="col-lg-4">
                        <span class="heading">Change login Password</span>  
                       
                        <div className="input-groups icons mt-4">
                          <h6 className="input-label">Old Password</h6>
                          <div className="flex_input_posion mb-2">
                          <input
                           type={inputType}
                           name="oldpassword"
                           minLength={15}
                           maxLength={30}
                           value={oldpassword}
                           onChange={handleChange}
                            className="input-field"
                            placeholder="Enter your old Password"
                          />
                           {passHide == true ? (
                              <i class="fa-regular fa-eye reg_eye" onClick={() => passwordHide("show")}></i>
                            
                          ) : (
                              <i class="fa-regular fa-eye-slash reg_eye" onClick={() => passwordHide("hide")}></i>
                          )}
                          </div>
                          {oldpassvalidate == true ? (
                                  <small className="errorcss">
                                    {validationnErr.oldpassword}
                                  </small>
                                ) : (
                                  ""
                                )}
                        </div>
                        <div className="input-groups icons">
                          <h6 className="input-label">New Password</h6>
                          <div className="flex_input_posion mb-2">
                          <input
                           type={inputTypenew}
                           name="password"
                           minLength={15}
                           maxLength={30}
                           value={password}
                           onChange={handleChange}
                            className="input-field"
                            placeholder="Enter your new password"
                          />
                           {passHidnew == true ? (
                              <i class="fa-regular fa-eye reg_eye" onClick={() => passwordHidenewP("show")}></i>
                            
                          ) : (
                              <i class="fa-regular fa-eye-slash reg_eye"  onClick={() => passwordHidenewP("hide")}></i>
                          )}
                          </div>
                          {passwordValidate == true ? (
                                  <small className="errorcss">
                                    {" "}
                                    {validationnErr.password}{" "}
                                  </small>
                                ) : (
                                  ""
                                )}
                        </div>
                        <div className="input-groups icons">
                          <h6 className="input-label">Confirm Password</h6>
                          <div className="flex_input_posion mb-2">
                          <input
                            type={inputTypeconf}
                            name="confirmPassword"
                            maxLength={30}
                            value={confirmPassword}
                            onChange={handleChange}
                            className="input-field"
                            placeholder="Re-Enter your new password"
                          />
                           {passHidconf == true ? (
                              <i class="fa-regular fa-eye reg_eye" onClick={() => passwordHideconf("show")}></i>
                            
                          ) : (
                              <i class="fa-regular fa-eye-slash reg_eye" onClick={() => passwordHideconf("hide")}></i>
                          )}
                          </div>
                          {confirmPasswordValidate == true ? (
                                  <small className="errorcss">
                                    {" "}
                                    {validationnErr.confirmPassword}{" "}
                                  </small>
                                ) : (
                                  ""
                                )}
                        </div>
      
                        <div className="Submit my-4">
                        {buttonLoader == false ? (
                          <button onClick={formSubmit}>Confirm</button>
                        ) : (
                          <button>Loading ...</button>
                        )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            </main>
          )}

    </>
  );
};

export default Anti;
