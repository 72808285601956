import React, { useRef, useEffect } from "react";
import useState from "react-usestateref";
import Footer from "./Footer";
import Header from "./Header";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import useInView from "../hooks/useInView";
import { getMethod, postMethod } from "../core/service/common.api";
import apiService from "../core/service/detail";
import { Link, useNavigate } from "react-router-dom";
import { gsap, Elastic, Power1 } from "gsap";

export default function Landing() {
  const settings = {
    dots: false,
    infinite: true,
    autoplay: true,
    speed: 200,
    autoplayspeed: 1000,
    slidesToShow: 6,
    slidesToScroll: 1,
    arrows: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 4,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
        },
      },
    ],
  };

  const cardtwoSettings = {
    dots: false,
    infinite: true,
    autoplay: true,
    speed: 200,
    autoplaySpeed: 3000,
    slidesToShow: 6,
    slidesToScroll: 1,
    arrows: false,
    rtl: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 4,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
        },
      },
    ],
  };

  const cardthreeSettings = {
    dots: false,
    infinite: true,
    autoplay: true,
    speed: 200,
    autoplayspeed: 5000,
    slidesToShow: 6,
    slidesToScroll: 1,
    arrows: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 4,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
        },
      },
    ],
  };

  const swiperCards = {
    dots: true,
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 2,
    autoplay: true,
    autoplaySpeed: 3000,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 425,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  const [colorChanged, setColorChanged] = useState(false);
  const [siteData, setSiteData] = useState("");
  const [siteStatus, setSiteStatus] = useState("");
  const [siteLoader, setSiteLoader] = useState(false);

  useEffect(() => {
    getSitedata();
    const timer = setTimeout(() => {
      setColorChanged(true);
    }, 2000);

    return () => clearTimeout(timer);
  }, []);

  const getSitedata = async () => {
    try {
      var data = {
        apiUrl: apiService.getSitedata,
      };
      setSiteLoader(true);
      var resp = await getMethod(data);
      setSiteLoader(false);
      if (resp.status == true) {
        setSiteData(resp.data);
        setSiteStatus(resp.data.siteStatus);
        console.log(resp, "=-=-=get site datas =-=-", resp.data.depositStatus);
      }
    } catch (error) {}
  };

  const [sectionRef, inView] = useInView({
    triggerOnce: true,
    threshold: 0.1,
  });

  const [advanceTradeRef, advanceTradeInView] = useInView({
    triggerOnce: true,
    threshold: 0.1,
  });

  const [availableRef, availableRefInView] = useInView({
    triggerOnce: true,
    threshold: 0.1,
  });

  const [keepRef, keepRefInView] = useInView({
    triggerOnce: true,
    threshold: 0.1,
  });

  const [secureRef, secureRefInView] = useInView({
    triggerOnce: true,
    threshold: 0.1,
  });

  useEffect(() => {
    const vsOpts = {
      slides: document.querySelectorAll(".v-slide"),
      list: document.querySelector(".v-slides"),
      duration: 12, // Increase duration for slower speed
      lineHeight: 50,
    };

    const vSlide = gsap.timeline({
      paused: true,
    });

    vsOpts.slides.forEach((slide, i) => {
      if (i === vsOpts.slides.length - 1) {
        // For the last slide, apply a fade-out effect
        vSlide.to(
          slide,
          vsOpts.duration / vsOpts.slides.length,
          {
            opacity: 0, // Fade out
            ease: Power1.easeInOut,
          },
          `+=${vsOpts.duration / vsOpts.slides.length}` // Delay for smooth transition
        );
      } else {
        // Normal slide-up effect for all other slides
        vSlide.to(vsOpts.list, vsOpts.duration / vsOpts.slides.length, {
          y: (i + 1) * -1 * vsOpts.lineHeight,
          ease: Elastic.easeOut.config(1, 0.4),
        });
      }
    });

    vSlide.play();
  }, []);

  useEffect(() => {
    const updateAOSAttributes = () => {
      const tradeBg = document.querySelector(".trade-bg");
      const tradeBgMob = document.querySelector(".trade-bg-mob");

      if (window.innerWidth > 768) {
        tradeBg.setAttribute("data-aos", "fade-left");
        tradeBgMob.setAttribute("data-aos", "fade-right");
      } else {
        tradeBg.removeAttribute("data-aos");
        tradeBgMob.removeAttribute("data-aos");
      }

      // Refresh AOS to apply changes
      window.AOS.refresh();
    };

    // Initial attribute update
    updateAOSAttributes();

    // Listen for window resize events
    window.addEventListener("resize", updateAOSAttributes);

    // Cleanup on component unmount
    return () => {
      window.removeEventListener("resize", updateAOSAttributes);
    };
  }, []);

  const [isLastSlide, setIsLastSlide] = useState(false);

  const textsettings = {
    dots: false,
    speed: 500,
    autoplaySpeed: 1500,
    infinite: true, // Keep infinite looping
    slidesToShow: 1,
    slidesToScroll: 1,
    vertical: true,
    autoplay: true,
    verticalSwiping: true,
    beforeChange: function (currentSlide, nextSlide) {
      // Assuming there are 3 slides and index is zero-based
      if (nextSlide === 2) {
        // Next slide is the last one
        setIsLastSlide(true);
      } else {
        setIsLastSlide(false); // Reset on other slides
      }
    },
  };

  const [loginCheck, setLoginCheck] = useState(false);

  useEffect(() => {
    let checkLogin = sessionStorage.getItem("user_token");
    console.log(checkLogin, "-----checkLogin----");
    if (checkLogin) {
      setLoginCheck(true);
    }
  }, []);

  const navigate = useNavigate();

  const tradeNav = () => {
    // {loginCheck ?
    navigate("/trade/BTC_USDT")
    // : navigate("/login") }
  }

  return (
    <>
      <main className="fidex_landing_main">
        <section>
          <Header />
        </section>

        {/* hero */}
        <section className="fidex_hero_section">
          <div className="container">
            <div className="row">
              <div className="col-lg-6 " data-aos="fade-right">
                <div className="hero_section_content ">
                  <h1>
                    Start Investing in Your Future Today with
                    <span
                      className={`crypto-span mar-lft  ${
                        colorChanged
                          ? "text-animate-inview text-animate"
                          : "text-animate"
                      }`}
                    >
                      VoltrixCrypt
                    </span>
                  </h1>
                  <p>
                    The future of crypto is here. Invest with confidence in a
                    platform designed to protect your assets and empower your
                    financial goals.
                  </p>
                  <Link
                    to={loginCheck ? "/dashboard" : "/register"}
                    className="get_started_btn"
                  >
                    Get Start Now<i className="fa-solid fa-arrow-right"></i>
                  </Link>
                </div>
              </div>
              <div
                className="col-lg-6 flex-end mobile-right"
                data-aos="fade-left"
              >
                <div className="hero_section_banner ">
                  {/* <img src={require("../assets/gif/mobile.gif")} /> */}
                  <img
                    src={
                      "https://res.cloudinary.com/dmjw7pe6k/image/upload/v1725424816/mobile_fqh3r6.gif"
                    }
                    alt=""
                  />
                </div>
              </div>
            </div>
          </div>
        </section>

        <section>
          <div className="container">
            <div className="row ">
              <div className="">
                <div className="landing-refer-container">
                  <div className="landing-refer">
                    <div className="container">
                      <div className="row announcement justify-content-between align-items-center">
                        <div className="col-lg-6">
                          <div className="d-flex align-item-center justify-content-between">
                            <div className="d-flex align-items-center">
                              <img
                                src={require("../assets/icons/speaker.webp")}
                                alt="speaker"
                                className="speaker-icon"
                              />
                              <div>
                                <Slider {...textsettings}>
                                  <div
                                    className={isLastSlide ? "fade-out" : ""}
                                  >
                                    <h3 className="landing-refer-title">
                                      Refer And Earn Up To $1000
                                    </h3>
                                  </div>
                                  <div
                                    className={isLastSlide ? "fade-out" : ""}
                                  >
                                    <h3 className="landing-refer-title">
                                      Get Up To 1000 VTX On your 1st Deposit
                                    </h3>
                                  </div>
                                  <div
                                    className={isLastSlide ? "fade-out" : ""}
                                  >
                                    <h3 className="landing-refer-title">
                                      Sign Up Now! And Earn Up To 10,000 VTX
                                    </h3>
                                  </div>
                                </Slider>
                              </div>
                            </div>
                            <div className="text-white landing-refer-arrow">
                              <i class="fa-solid fa-circle-arrow-right"></i>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-5">
                          <div className="d-flex justify-content-between ml-3">
                            <div>
                              <Slider
                                {...textsettings}
                                className="bottom-slicks"
                              >
                                <div className={isLastSlide ? "fade-out" : ""}>
                                  <h3 className="landing-refer-title">
                                    Make Your First Trade & Pay 0 Trading Fees!
                                  </h3>
                                </div>
                                <div className={isLastSlide ? "fade-out" : ""}>
                                  <h3 className="landing-refer-title">
                                    Start Staking VTX to Earn Rewards
                                  </h3>
                                </div>
                                <div className={isLastSlide ? "fade-out" : ""}>
                                  <h3 className="landing-refer-title">
                                    Join Our Airdrop Contests & Win Big!
                                  </h3>
                                </div>
                              </Slider>
                            </div>
                            <div className="text-white landing-refer-arrow">
                              <i class="fa-solid fa-circle-arrow-right"></i>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* slider cards*/}
        <section className="slider-card-section">
          <div className="container">
            <div className="row">
              <div className="">
                <Slider {...swiperCards}>
                  <div className="swiper-cards">
                    <img
                      src={require("../assets/swipercard-one.webp")}
                      alt=""
                    />
                  </div>
                  <div className="swiper-cards">
                    {" "}
                    <img
                      src={require("../assets/swipercard-two.webp")}
                      alt=""
                    />
                  </div>
                  <div className="swiper-cards">
                    {" "}
                    <img
                      src={require("../assets/swipercard-three.webp")}
                      alt=""
                    />
                  </div>
                  <div className="swiper-cards">
                    {" "}
                    <img
                      src={require("../assets/swipercard-four.webp")}
                      alt=""
                    />
                  </div>
                  <div className="swiper-cards">
                    {" "}
                    <img
                      src={require("../assets/swipercard-five.webp")}
                      alt=""
                    />
                  </div>
                  <div className="swiper-cards">
                    {" "}
                    <img
                      src={require("../assets/swipercard-six.webp")}
                      alt=""
                    />
                  </div>
                </Slider>
              </div>
            </div>
          </div>
        </section>

        {/* Get Started cards */}
        <section ref={sectionRef} className="get-started-section">
          <div className="container">
            <div>
              <h5 className="get-started-title" data-aos="fade-up">
                Unlock Trading Success in{" "}
                <span
                  className={`text-y ${
                    inView ? "text-animate text-animate-inview" : "text-animate"
                  }`}
                >
                  Just 6 Steps!
                </span>
              </h5>
              <p className="get-started-content" data-aos="fade-up">
                Experience a hassle-free trading experience from start to
                finish. Our clear guidance ensures you're never lost along the
                way.
              </p>
            </div>

            <div className="process-card-wrapper " data-aos="fade-up">
              <div className="row">
                <div className="col-lg-4">
                  <div className="process-cards pro-card-one">
                    <img
                      src={
                        "https://res.cloudinary.com/dmjw7pe6k/image/upload/v1725352820/qugkmsoil9se2kfzo95m.gif"
                      }
                      alt=""
                    />
                    <h6>Sign Up Today!</h6>
                    <p className="mar-y-5">
                      Unlock a world of trading opportunities in just a few
                      steps. Sign up now using your email or mobile number to
                      create your account and get started instantly.
                    </p>
                    <Link to={loginCheck ? "/dashboard" : "/register"}>
                      <button className="process-get-btn">
                        Get Started <i className="fa-solid fa-arrow-right"></i>
                      </button>
                    </Link>
                  </div>
                </div>
                <div className="col-lg-4 mar-t-24">
                  <div className="process-cards pro-card-two">
                    <img
                      src={
                        "https://res.cloudinary.com/dmjw7pe6k/image/upload/v1725352819/pia9qsa2pzfm4nda3ryw.gif"
                      }
                      alt=""
                    />
                    <h6>Verify Your Identity</h6>
                    <p className="mar-y-5">
                      Quickly sign up with your email. Our secure registration
                      gets you ready to trade in no time.
                    </p>
                    <Link to={loginCheck ? "/kyc" : "/login"}>
                      <button className="process-get-btn">
                        Get Started <i className="fa-solid fa-arrow-right"></i>
                      </button>
                    </Link>
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="process-cards pro-card-three">
                    <img
                      src={
                        "https://res.cloudinary.com/dmjw7pe6k/image/upload/v1725352820/fa9fsaytqcpuy5gjlrrr.gif"
                      }
                      alt=""
                    />
                    <h6>Deposit Funds</h6>
                    <p className="mar-y-5">
                      Deposit your crypto, INR, or USD securely to engage in
                      trades and explore a range of opportunities.
                    </p>
                    <Link to={loginCheck ? "/deposit" : "/login"}>
                      <button className="process-get-btn">
                        Get Started <i className="fa-solid fa-arrow-right"></i>
                      </button>
                    </Link>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-lg-4 -mar-t-55">
                  <div className="process-cards pro-card-five">
                    <img
                      src={
                        "https://res.cloudinary.com/dmjw7pe6k/image/upload/v1725352819/ec5u98hghnhac2xp9yac.gif"
                      }
                      alt=""
                    />
                    <h6>Withdraw Funds</h6>
                    <p className="mar-y-5">
                      Instantly withdraw your funds securely on our Voltrix
                      platform anytime.
                    </p>
                    <Link to={loginCheck ? "/withdraw" : "/login"}>
                      <button className="process-get-btn">
                        Get Started <i className="fa-solid fa-arrow-right"></i>
                      </button>
                    </Link>
                  </div>
                </div>
                <div className="col-lg-4 mar-t-20">
                  <div className="process-cards pro-card-six">
                    <img
                      src={
                        "https://res.cloudinary.com/dmjw7pe6k/image/upload/v1725352819/qlamwfpfsg4mosmeeed8.gif"
                      }
                      alt=""
                    />
                    <h6>Maintain Your Portfolio</h6>
                    <p className="mar-y-5">
                      Easily manage and grow your investments with our
                      platform's powerful tools, including trading, P2P
                      transactions, and staking rewards. Optimize your portfolio
                      effortlessly!
                    </p>
                    <Link to={loginCheck ? "/security" : "/login"}>
                      <button className="process-get-btn">
                        Get Started <i className="fa-solid fa-arrow-right"></i>
                      </button>
                    </Link>
                  </div>
                </div>
                <div className="col-lg-4 -mar-t-55 ">
                  <div className="process-cards pro-card-four">
                    <img
                      src={
                        "https://res.cloudinary.com/dmjw7pe6k/image/upload/v1725352820/dp10zj2ilb7dpns9g1it.gif"
                      }
                      alt=""
                    />
                    <h6>Start Trading</h6>
                    <p className="mar-y-5">
                      Trade multiple cryptocurrencies easily and boost your
                      profits effortlessly today.
                    </p>
                    <Link to={loginCheck ? "/trade/BTC_USDT" : "/login"}>
                      <button className="process-get-btn">
                        Get Started <i className="fa-solid fa-arrow-right"></i>
                      </button>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* advanced trading */}
        <section ref={advanceTradeRef} className="advance_trade_section">
          <div className="container">
            <div className="advance_trade_title" data-aos="fade-up">
              <p>
                Advanced Trading with{" "}
                <span
                  className={`text-y ${
                    advanceTradeInView
                      ? "text-animate text-animate-inview"
                      : "text-animate"
                  }`}
                >
                  Voltrix Crypt
                </span>
              </p>

              <p className="advance_subcontent my-3">
                Uncover the secrets of cryptocurrency trading and investment
              </p>
            </div>

            <div className="row" data-aos="fade-up">
              <div className="col-lg-4">
                <div className="fast_tranSsaction_card">
                  <div className="fast-cards">
                    <div className="fast-transImg-wrapper-one">
                      <img
                        src={require("../assets/icons/fast_transaction_icon.webp")}
                        className="fast-trans-img"
                      />
                    </div>
                    <h4 className="fast-trans-title">Fast Transactions</h4>
                    <p>
                      Experience lightning-fast trade executions with minimal
                      latency.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="fast_tranSsaction_card">
                  <div className="fast-cards">
                    <div className="fast-transImg-wrapper-two">
                      <img
                        src={require("../assets/icons/low_fees.webp")}
                        className="fast-trans-img"
                      />
                    </div>
                    <h4 className="fast-trans-title">Low Fees</h4>
                    <p>
                      Enjoy competitive rates with a transparent fee structure.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="fast_tranSsaction_card">
                  <div className="fast-cards">
                    <div className="fast-transImg-wrapper-three">
                      <img
                        src={require("../assets/icons/robust_icon.webp")}
                        className="fast-trans-img"
                      />
                    </div>
                    <h4 className="fast-trans-title">Robust Security</h4>
                    <p>
                      Advanced encryption and secure cold storage for maximum
                      asset protection.
                    </p>
                  </div>
                </div>
              </div>
              <div className="text-center ad-btn-wrapper" onClick={()=>tradeNav()}>
                <button className="advance-start-btn">
                  Start Trading{" "}
                  <>
                    <i class="fa-solid fa-arrow-right"></i>
                  </>
                </button>
              </div>
            </div>
          </div>
        </section>

        {/* Available Crypto */}
        <section>
          <div className="container">
            <div className="available-card">
              <div className="available-crypto-section">
                <h5 ref={availableRef} data-aos="fade-up">
                  Advanced Benefits of{" "}
                  <span
                    className={`text-y ${
                      availableRefInView
                        ? "text-animate text-animate-inview"
                        : "text-animate"
                    }`}
                  >
                    VoltrixCrypto
                  </span>{" "}
                </h5>

                <p data-aos="fade-up">
                  Utilize VoltrixCrypto’s advanced benefits and maximize your
                  profits without any breakdowns. Enjoy seamless trading and
                  powerful features designed to enhance your investment
                  experience.
                </p>

                <div data-aos="fade-up">
                  {/* first slider */}
                  <div className="slider-wrapper">
                    <Slider {...settings}>
                      <div className="slider-coin-wrapper">
                        <img
                          src={require("../assets/eth.png")}
                          alt="eth"
                          className="slider-coin"
                        />
                        <span className="slider-coin-title">Ethereum</span>
                      </div>
                      <div className="slider-coin-wrapper">
                        <img
                          src={require("../assets/btc.png")}
                          alt="btc"
                          className="slider-coin"
                        />
                        <span className="slider-coin-title">Bitcoin</span>
                      </div>
                      <div className="slider-coin-wrapper">
                        <img
                          src={require("../assets/xrp.png")}
                          alt="btc"
                          className="slider-coin"
                        />
                        <span className="slider-coin-title">Ripple</span>
                      </div>
                      <div className="slider-coin-wrapper">
                        <img
                          src={require("../assets/usdt.png")}
                          alt="btc"
                          className="slider-coin"
                        />
                        <span className="slider-coin-title">Tither</span>
                      </div>
                      <div className="slider-coin-wrapper">
                        <img
                          src={require("../assets/matic.png")}
                          alt="btc"
                          className="slider-coin"
                        />
                        <span className="slider-coin-title">Polygon</span>
                      </div>
                      <div className="slider-coin-wrapper">
                        <img
                          src={require("../assets/chainlink.png")}
                          alt="chainlink"
                          className="slider-coin"
                        />
                        <span className="slider-coin-title">Chainlink</span>
                      </div>
                      <div className="slider-coin-wrapper">
                        <img
                          src={require("../assets/bnb.png")}
                          alt="btc"
                          className="slider-coin"
                        />
                        <span className="slider-coin-title">BNB</span>
                      </div>
                      <div className="slider-coin-wrapper">
                        <img
                          src={require("../assets/xrp.png")}
                          alt="btc"
                          className="slider-coin"
                        />
                        <span className="slider-coin-title">Ripple</span>
                      </div>
                    </Slider>
                  </div>

                  {/* second slider */}
                  <div className="slider-wrapper">
                    <div className="slider-cardTwo">
                      <Slider {...cardtwoSettings}>
                        <div className="slider-coin-wrapper">
                          <img
                            src={require("../assets/eth.png")}
                            alt="eth"
                            className="slider-coin"
                          />
                          <span className="slider-coin-title">Ethereum</span>
                        </div>
                        <div className="slider-coin-wrapper">
                          <img
                            src={require("../assets/btc.png")}
                            alt="btc"
                            className="slider-coin"
                          />
                          <span className="slider-coin-title">Bitcoin</span>
                        </div>
                        <div className="slider-coin-wrapper">
                          <img
                            src={require("../assets/xrp.png")}
                            alt="btc"
                            className="slider-coin"
                          />
                          <span className="slider-coin-title">Ripple</span>
                        </div>
                        <div className="slider-coin-wrapper">
                          <img
                            src={require("../assets/usdt.png")}
                            alt="btc"
                            className="slider-coin"
                          />
                          <span className="slider-coin-title">Tither</span>
                        </div>
                        <div className="slider-coin-wrapper">
                          <img
                            src={require("../assets/matic.png")}
                            alt="btc"
                            className="slider-coin"
                          />
                          <span className="slider-coin-title">Polygon</span>
                        </div>
                        <div className="slider-coin-wrapper">
                          <img
                            src={require("../assets/chainlink.png")}
                            alt="chainlink"
                            className="slider-coin"
                          />
                          <span className="slider-coin-title">Chainlink</span>
                        </div>
                        <div className="slider-coin-wrapper">
                          <img
                            src={require("../assets/bnb.png")}
                            alt="btc"
                            className="slider-coin"
                          />
                          <span className="slider-coin-title">BNB</span>
                        </div>
                        <div className="slider-coin-wrapper">
                          <img
                            src={require("../assets/xrp.png")}
                            alt="btc"
                            className="slider-coin"
                          />
                          <span className="slider-coin-title">Ripple</span>
                        </div>
                      </Slider>
                    </div>
                  </div>

                  {/* third slider */}
                  <div className="slider-wrapper">
                    <Slider {...cardthreeSettings}>
                      <div className="slider-coin-wrapper">
                        <img
                          src={require("../assets/eth.png")}
                          alt="eth"
                          className="slider-coin"
                        />
                        <span className="slider-coin-title">Ethereum</span>
                      </div>
                      <div className="slider-coin-wrapper">
                        <img
                          src={require("../assets/btc.png")}
                          alt="btc"
                          className="slider-coin"
                        />
                        <span className="slider-coin-title">Bitcoin</span>
                      </div>
                      <div className="slider-coin-wrapper">
                        <img
                          src={require("../assets/xrp.png")}
                          alt="btc"
                          className="slider-coin"
                        />
                        <span className="slider-coin-title">Ripple</span>
                      </div>
                      <div className="slider-coin-wrapper">
                        <img
                          src={require("../assets/usdt.png")}
                          alt="btc"
                          className="slider-coin"
                        />
                        <span className="slider-coin-title">Tither</span>
                      </div>
                      <div className="slider-coin-wrapper">
                        <img
                          src={require("../assets/matic.png")}
                          alt="btc"
                          className="slider-coin"
                        />
                        <span className="slider-coin-title">Polygon</span>
                      </div>
                      <div className="slider-coin-wrapper">
                        <img
                          src={require("../assets/chainlink.png")}
                          alt="chainlink"
                          className="slider-coin"
                        />
                        <span className="slider-coin-title">Chainlink</span>
                      </div>
                      <div className="slider-coin-wrapper">
                        <img
                          src={require("../assets/bnb.png")}
                          alt="btc"
                          className="slider-coin"
                        />
                        <span className="slider-coin-title">BNB</span>
                      </div>
                      <div className="slider-coin-wrapper">
                        <img
                          src={require("../assets/xrp.png")}
                          alt="btc"
                          className="slider-coin"
                        />
                        <span className="slider-coin-title">Ripple</span>
                      </div>
                    </Slider>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* keep trading */}
        <section ref={keepRef} className="keep_trade_section">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="keep_trade_content">
                  <h2 data-aos="fade-up">
                    <span>Trade Anytime, Anywhere,</span>
                    <span
                      className={`text-y ${
                        keepRefInView
                          ? "text-animate text-animate-inview"
                          : "text-animate"
                      }`}
                    >
                      with Ease.
                    </span>{" "}
                  </h2>
                  <p data-aos="fade-up">
                    Access VoltrixCrypto from any device and execute trades
                    seamlessly, wherever you are. Enjoy the flexibility to
                    manage your investments on your own terms.
                  </p>

                  <div className="play_apps my-4" data-aos="fade-up">
                    <a href="">
                      <img src={require("../assets/gplay.webp")} />
                    </a>
                    <a href="">
                      <img src={require("../assets/ipay.webp")} />
                    </a>
                  </div>

                  <div className="my-5 trade-bg-container">
                    {/* <div className="min-md-none">
                      <img
                        src={require(`../assets/keep-trade-bg.png`)}
                        alt="trading-bg"
                        className="trade-bg"
                      />
                    </div> */}

                    <div className="">
                      <img
                        src={
                          "https://res.cloudinary.com/dmjw7pe6k/image/upload/v1725368798/keep-trading-gif_bthdjo.gif"
                        }
                        // src={require("../assets/gif/keep-trading-gif.gif")}
                        alt="trading-bg"
                        className="trade-bg"
                      />
                      <div className="trade-bg-mob">
                        <img
                          src={
                            "https://res.cloudinary.com/dmjw7pe6k/image/upload/v1725424816/mobile_fqh3r6.gif"
                          }
                          // src={require("../assets/gif/mobile.gif")}
                          alt="trade-mobile"
                          className="trade-mob"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* security */}
        <section ref={secureRef} className="refer_earn_section">
          <div className="container">
            <div className="row">
              <div className="refer-title">
                <h6>
                  Security & Safety{" "}
                  <span
                    className={`text-y ${
                      secureRefInView
                        ? "text-animate text-animate-inview"
                        : "text-animate"
                    }`}
                  >
                    Standards
                  </span>
                </h6>
                <p>
                  VoltrixCrypt ensures top-tier protection, adhering to the
                  highest international security protocols for your peace of
                  mind.
                </p>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-4">
                <div className="security-cards">
                  <img
                    src={require("../assets/icons/proof.webp")}
                    alt="proof"
                  />
                  <h3>Proof of Reserve</h3>

                  <p>
                    All assets are regularly monitored and audited by a third
                    party to ensure transparency and security.
                  </p>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="security-cards">
                  <img src={require("../assets/icons/fiu.webp")} alt="fiu" />
                  <h3>F.I.U. Compliant</h3>

                  <p>
                    Adhering to Indian anti-money laundering laws, ensuring
                    secure and lawful transactions on our platform.
                  </p>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="security-cards">
                  <img src={require("../assets/icons/so.webp")} alt="so" />
                  <h3>SO/IEC 27001:2022 Certified</h3>

                  <p>
                    Adhering to global security standards, ensuring the highest
                    level of protection for your data and transactions.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* footer */}
        <section className="footer_section">
          <Footer />
        </section>
      </main>
    </>
  );
}
